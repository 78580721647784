import component from "./Home";

import copy from "./copy.json";

export const paths = {
  home: {
    to: "/",
    title: copy.paths.home.title,
  },
};

export default [
  {
    exact: true,
    path: paths.home.to,
    component,
  },
];
