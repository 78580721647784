import React from "react";

const Icon = props => (
  <svg viewBox="0 0 212 160" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <circle stroke="currentColor" cx="106" cy="80" r="75" fill="none" strokeMiterlimit="10" strokeWidth="8" />
    <path fill="currentColor" d="M69.22 88.31a12.86 12.86 0 1 0 12.89 12.86 12.89 12.89 0 0 0-12.89-12.86Zm.08 21.43a8.58 8.58 0 1 1 8.52-8.52 8.6 8.6 0 0 1-8.52 8.52Z" />
    <path fill="currentColor" d="M69.32 94.74a6.43 6.43 0 1 0 6.36 6.46 6.47 6.47 0 0 0-6.36-6.46Zm-.1 8.55a2.18 2.18 0 0 1-2.09-2.16A2.16 2.16 0 0 1 69.29 99a2.13 2.13 0 1 1-.07 4.25ZM50.49 28.9l5.42-5.42L161.53 129.1l-5.42 5.42z" />
    <path fill="currentColor" d="M130 115.42a7.54 7.54 0 0 1-3.56.79c-14.19-.08-28.39 0-42.59 0a5 5 0 0 1-2-.18c4.79-5.4 9-11.09 13.45-16.62 2.77-3.45 5.51-6.91 8.27-10.37l-3-3Q95.2 92.78 89.8 99.51a2.69 2.69 0 0 1-1.18 1.09 22.51 22.51 0 0 0-1.93-7.6 19.21 19.21 0 0 0-20-10.9c-.37 0-.91.3-1.07-.15s.35-.77.61-1.11l12.89-16.31-3.07-3.06Q65.52 74.76 55 88a21 21 0 0 0-5 12.17 19.32 19.32 0 0 0 19.2 20.28h57.89a10.15 10.15 0 0 0 6.1-1.87Zm-60.73.75a15 15 0 1 1 15-15 15 15 0 0 1-15 15ZM169.3 67.65a5.56 5.56 0 0 0-5.3-2.93h-38.32c-.81 0-2 .06-2.15-.23s.54-1.38 1-2c1.83-2.7 3.8-5.34 4.48-8.61a19.64 19.64 0 0 0-10.8-21.59 19.3 19.3 0 0 0-23.4 5.62l-8.22 10.26 3.06 3.06 1.27-1.61c2.33-2.94 4.64-5.9 7-8.82 6.16-7.7 16.77-8.17 23.26-1.11 3.67 4 5.69 11.91 1.44 18-3.63 5.17-7.55 10.12-11.47 15.07l3 3 4.28-5.37a3.2 3.2 0 0 1 2.79-1.39q21 .06 41.93 0h.94a1.46 1.46 0 0 1 1.46.75 1.4 1.4 0 0 1-.32 1.61q-2.53 3.28-5.1 6.55l-19.2 24.59 3 3q5.17-6.5 10.32-13.1l14.27-18.27c1.57-2.01 2.03-4.13.78-6.48Z" />
  </svg>
);

export default Icon;
