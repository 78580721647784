import React from "react";

import RugIcon from "../components/icons/Rug";
import SnipeIcon from "../components/icons/Snipe";
import AuditIcon from "../components/icons/Audit";
import CostIcon from "../components/icons/Cost";
import GearIcon from "../components/icons/Gear";
import ScamIcon from "../components/icons/Scam";

const general = {
  businessName: 'iLaunch',
  telegramURL: 'https://t.me/iLaunch',
  twitterURL: 'https://twitter.com/iLaunchService',
}

const header = {
  logoSrc1: 'header-logo.png',
};

const intro = {
  videoBgSrc: 'launch.mp4',
  imgBgSrc: 'intro-bg.png',
  imgBgOpacity: 0.8,
  heading: 'The Security-centric Launch Solution',
  subHeading: '',
  mainImgSrc: 'intro-main.png',
  copyParas: [
    'iLaunch specialises in creating 100% safe &amp; configurable smart contracts, executing fast and secure token launches, and assists with initial post-launch promotion.',
    'By providing a solid technical foundation to safeguard investors, each legitimate project stands a greater chance of success.',
  ],
};

const benefitsMarquee = {
  speed: 100,
  bullets: [
    'Reduced Snipers',
    'Enforced Security',
    'Unscammable',
    'Unruggable',
    'Smooth Launched',
    'Tailored Tokenomics',
    'Full Transparency',
    'Trustless',
    'Promo Boost',
    'Reduced FUD',
    'Cost Effective',
    'Dedicated Support',
  ]
};

const features = {
  heading: 'Features',
  subHeading: 'A safe and cost-effective way to launch',
  catLists: {
    unscamable: {
      title: 'Unscammable',
      icon: <ScamIcon />,
      copy: [
        'Using trustless principles, our smart contracts are engineered to prevent malicious configuration irrespective of who the contract owner may be.',
        'Honeypots, balance sweepers, hidden mints, or any other form of contract based scam are <em>technically impossible</em> to achieve.',
      ],
    },
    unruggable: {
      title: 'Unruggable',
      icon: <RugIcon />,
      copy: [
        'Immediately after launch only iLaunch has access to liquidity pool (LP) tokens, and we alone <em>ensure</em> they cannot be used to rugpull.',
        'On behalf of the client and investors, we lock all LP tokens for a minimum period then transfer lock extension control to the client.',
      ],
    },
    antiSnipe: {
      title: 'Anti-snipe',
      icon: <SnipeIcon />,
      copy: [
        'We employ specialist techniques to deploy and launch in minimial time that in combination with our anti-bot logic and sniper blacklist, <em>iBlock</em>, eliminates the overwhelming majority of sniper bots.',
        'Fewer snipers equates to a greater ratio of manual buyers and healthier price action.',
      ],
    },
    audit: {
      title: 'Auditless',
      icon: <AuditIcon />,
      copy: [
        'Most functional audits are expensive, lengthy, and fail to fully address vital security concerns. For example, a smart contract may pass inspection yet still be used to defraud.',
        'Our safety-centric, fully transparent approach prohibits the initial need for a third party audit or security review.',
      ],
    },
    cost: {
      title: 'Cost-effective',
      icon: <CostIcon />,
      copy: [
        'GAS costs aside, our fee includes: contract development; testing; managed deployment, launch and security; and post launch support.',
        'In addition, each project receives a free advertising slot on <a href="https://t.me/iTokenEthereum" target="_blank" rel="noopener noreferrer">iToken</a>, promotion through iLaunch\'s social media channels, plus a 25% discount with selected channels/influencers.',
      ],
    },
    custom: {
      title: 'Bespoke',
      icon: <GearIcon />,
      copy: [
        'Our smart contracts are modified to suit each clients specific requirements. A variety of features are available, including: auto-liquidity; supply burns; anti-whale; anti-bot; multiple designated tax wallets; and more.',
        'Whatever features you choose, everything remains safely configurable to accommodate future growth.',
      ],
    },
  }
};

const guide = {
  heading: 'Guide',
  subHeading: 'A simple, fast and secure process that\'s open to all',
  stepLists: {
    options: {
      title: 'Choose your tokenomics',
      intro: 'If you\'re unsure about your tokenomics, we can easily guide you. Here are some straightforward options to consider:',
      catLists: {
        liquidity: {
          title: 'Auto-liquidity',
          copy: 'Per swap, a percentage of tokens are used to auto-add liquidity, helping to form a more stable price floor. It doubles as a deflationary mechanism, and may be toggled on/off.',
        },
        burns: {
          title: 'Auto-burn',
          copy: 'Per swap, a percentage of tokens are removed from existence, reducing the circulating <em>and</em> total supply. It\'s a deflationary mechanism, and may be toggled on/off.',
        },
        wallets: {
          title: 'Project Wallets',
          copy: 'For trade tax contracts, decide what percentage should be sent to each wallet e.g. 2% to team, 2% to marketing, etc. No more than three wallets are recomended.',
        },
        tax: {
          title: 'Tax or Taxless',
          copy: 'We provide smart contracts with or without a trade tax. If required, a trade tax helps to fund various running costs e.g. team payments, marketing, buybacks, donations, etc.',
        },
      },
      outro: '<em>Each contract includes anti-whale, anti-dump, anti-bot, as well as strict safety limits to prevent malicious configuration.</em>',
    },
    form: {
      title: 'Complete the application form',
      intro: 'This gives us a general idea of what you expect from your token and launch. All information submitted is strictly confidential.',
      applyBtnUrl: 'https://forms.gle/uQmfoe4B1TqjQiFx8',
      applyBtnCopy: 'Apply',
    },
    contact: {
      title: 'Contact us',
      intro: 'Once you\'re ready to discuss your requirements, please contact us via Telegram, ensuring that the contact username is <strong>@IndyInvest</strong>.',
      contactBtnUrl: 'https://t.me/IndyInvest',
      contactBtnCopy: 'Send Message',
    },
  },
};

export default {
  general,
  header,
  intro,
  benefitsMarquee,
  features,
  guide,
};
