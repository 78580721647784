import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import _map from "lodash/map";

import store from "../store/config";

import Page from "./page/Page";

const renderRoutes = (routes) =>
  _map(routes, (route) => {
    const render = (props) => {
      const Component = route.component;

      return <Component {...props} />;
    };

    return (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        render={render}
      />
    );
  });

const App = ({ routes }) => (
  <Provider store={store}>
    <Router>
      <Page>
        <Switch>{renderRoutes(routes)}</Switch>
      </Page>
    </Router>
  </Provider>
);

App.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default App;
