import React from "react";
import Fade from "react-reveal/Fade";

import config from "../../../config/config";

import styles from "./features.module.scss";

const Features = () => {
  return (
    <section id="features" className={styles.cnt}>
      <div className={styles.cntInA}>
        <h1 className={styles.cntHeading} dangerouslySetInnerHTML={{ __html: config.features.heading }} />
        {config.features.subHeading && (
          <h2 className={styles.cntSubHeading} dangerouslySetInnerHTML={{ __html: config.features.subHeading }} />
        )}
      </div>
      <div className={styles.cntInB}>
        <ul className={styles.cntCatList}>
          <Fade bottom distance="75px">
            <li>
              <div className={styles.catIcon}>{config.features.catLists.custom.icon}</div>
              <h2 className={styles.catHeading}>{config.features.catLists.custom.title}</h2>
              <div className={styles.cntCatSummary}>
                {config.features.catLists.custom.copy.map((item, idx) => (<p key={`item${idx}`} dangerouslySetInnerHTML={{ __html: item }} />))}
              </div>
            </li>
          </Fade>
          <Fade bottom distance="75px">
            <li>
              <div className={styles.catIcon}>{config.features.catLists.antiSnipe.icon}</div>
              <h2 className={styles.catHeading}>{config.features.catLists.antiSnipe.title}</h2>
              <div className={styles.cntCatSummary}>
                {config.features.catLists.antiSnipe.copy.map((item, idx) => (<p key={`item${idx}`} dangerouslySetInnerHTML={{ __html: item }} />))}
              </div>
            </li>
          </Fade>
          <Fade bottom distance="75px">
            <li>
              <div className={styles.catIcon}>{config.features.catLists.cost.icon}</div>
              <h2 className={styles.catHeading}>{config.features.catLists.cost.title}</h2>
              <div className={styles.cntCatSummary}>
                {config.features.catLists.cost.copy.map((item, idx) => (<p key={`item${idx}`} dangerouslySetInnerHTML={{ __html: item }} />))}
              </div>
            </li>
          </Fade>
        </ul>
        <ul className={styles.cntCatList}>
          <Fade bottom distance="75px">
            <li>
              <div className={styles.catIcon}>{config.features.catLists.unscamable.icon}</div>
              <h2 className={styles.catHeading}>{config.features.catLists.unscamable.title}</h2>
              <div className={styles.cntCatSummary}>
                {config.features.catLists.unscamable.copy.map((item, idx) => (<p key={`item${idx}`} dangerouslySetInnerHTML={{ __html: item }} />))}
              </div>
            </li>
          </Fade>
          <Fade bottom distance="75px">
            <li>
              <div className={styles.catIcon}>{config.features.catLists.unruggable.icon}</div>
              <h2 className={styles.catHeading}>{config.features.catLists.unruggable.title}</h2>
              <div className={styles.cntCatSummary}>
                {config.features.catLists.unruggable.copy.map((item, idx) => (<p key={`item${idx}`} dangerouslySetInnerHTML={{ __html: item }} />))}
              </div>
            </li>
          </Fade>
          <Fade bottom distance="75px">
            <li>
              <div className={styles.catIcon}>{config.features.catLists.audit.icon}</div>
              <h2 className={styles.catHeading}>{config.features.catLists.audit.title}</h2>
              <div className={styles.cntCatSummary}>
                {config.features.catLists.audit.copy.map((item, idx) => (<p key={`item${idx}`} dangerouslySetInnerHTML={{ __html: item }} />))}
              </div>
            </li>
          </Fade>
        </ul>
      </div>
    </section>
  );
}

export default Features;
