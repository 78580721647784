import React from "react";
import Fade from "react-reveal/Fade";

import config from "../../../config/config";

import styles from "./guide.module.scss";

const Guide = () => {
  return (
    <section id="guide" className={styles.cnt}>
      <div className={styles.cntInA}>
        <h1 className={styles.cntHeading} dangerouslySetInnerHTML={{ __html: config.guide.heading }} />
        {config.guide.subHeading && (
          <h2 className={styles.cntSubHeading} dangerouslySetInnerHTML={{ __html: config.guide.subHeading }} />
        )}
      </div>
      <div className={styles.cntInB}>
        <ul className={styles.cntStepList}>
          <Fade bottom distance="75px">
            <li className={styles.cntStepListItem}>
              <h2 className={styles.stepHeading}>{config.guide.stepLists.options.title}</h2>
              <p className={styles.stepIntro}>{config.guide.stepLists.options.intro}</p>
              <ul className={`${styles.cntStepCatListBase} ${styles.cntStepCatListA}`}>
                <li>
                  <h3 className={styles.stepCatHeading} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.options.catLists.tax.title }} />
                  <p className={styles.stepCatSummary} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.options.catLists.tax.copy }} />
                </li>
                <li>
                  <h3 className={styles.stepCatHeading} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.options.catLists.wallets.title }} />
                  <p className={styles.stepCatSummary} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.options.catLists.wallets.copy }} />
                </li>
                <li>
                  <h3 className={styles.stepCatHeading} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.options.catLists.liquidity.title }} />
                  <p className={styles.stepCatSummary} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.options.catLists.liquidity.copy }} />
                </li>
              </ul>
              <p className={styles.stepOutro} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.options.outro }} />
            </li>
          </Fade>
          <Fade bottom distance="75px">
            <li id="apply" className={styles.cntStepListItem}>
              <h2 className={styles.stepHeading}>{config.guide.stepLists.form.title}</h2>
              <p className={styles.stepIntro}>{config.guide.stepLists.form.intro}</p>
              <div className={styles.cntStepBtn}>
                <a href={config.guide.stepLists.form.applyBtnUrl} className={styles.stepBtnLink} target="_blank" rel="noopener noreferrer">{config.guide.stepLists.form.applyBtnCopy}</a>
              </div>
            </li>
          </Fade>
          <Fade bottom distance="75px">
            <li id="contact" className={styles.cntStepListItem}>
              <h2 className={styles.stepHeading}>{config.guide.stepLists.contact.title}</h2>
              <p className={styles.stepIntro} dangerouslySetInnerHTML={{ __html: config.guide.stepLists.contact.intro }} />
              <div className={styles.cntStepBtn}>
                <a href={config.guide.stepLists.contact.contactBtnUrl} className={styles.stepBtnLink} target="_blank" rel="noopener noreferrer">{config.guide.stepLists.contact.contactBtnCopy}</a>
              </div>
            </li>
          </Fade>
        </ul>
      </div>
    </section>
  );
}

export default Guide;
