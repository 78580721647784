import React, { useState } from "react";
import { Link } from "react-scroll";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import _map from "lodash/map";

import MenuIcon from "../../../components/icons/Menu";
import CloseBtn from "../../../components/buttons/Close";

import config from "../../../config/config";

import styles from "./nav-drawer.module.scss";

const renderNavList = toggleDrawer => {
  let parentIdx = -1;

  return _map(
    [
      [{ type: "divider" }],
      [
        { type: "anchor", title: "Features", path: "features", offset: -76 },
        { type: "anchor", title: "Guide", path: "guide", offset: -76 },
        { type: "anchor", title: "Apply", path: "apply", offset: -120 },
      ],
      [{ type: "divider" }],
      [
        { type: "external", title: "Telegram", path: config.general.telegramURL },
        { type: "external", title: "Twitter", path: config.general.twitterURL },
      ],
    ],
    (items) => {
      parentIdx++;

      if (items[0].type === "divider") return <Divider key={`divider${parentIdx}`} />;

      let childIdx = -1;

      return (
        <ul className={styles.list} key={`list${parentIdx}`}>
          {_map(items, ({ type, title, path, offset }) => {
            childIdx++;

            return (
              <li key={`item${childIdx}`}>
                {type === "anchor" ? (
                  <Link to={path} className={styles.txtLink} smooth={true} duration={500} offset={offset} onClick={toggleDrawer}>
                    {title}
                  </Link>
                ) : (
                  <a
                    href={path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.txtLink}
                  >
                    {title}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      );
    }
  );
};

const NavDrawer = () => {
  const [isOpen, setOpen] = useState(false);
  const anchor = "left";

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;

    setOpen(open);
  };

  const renderList = () => (
    <div
      className={styles.cntList}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {renderNavList(toggleDrawer(false))}
    </div>
  );

  return (
    <div className={styles.cnt}>
      <button
        className={styles.menuBtn}
        onClick={toggleDrawer(true)}
        disabled={isOpen}
        aria-label="Open Menu"
        aria-haspopup="true"
      >
        <MenuIcon />
      </button>
      <Drawer
        className={styles.modal}
        anchor={anchor}
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <div className={styles.header}>
          <CloseBtn onClick={toggleDrawer(false)} />
          <img src="/images/drawer-logo.png" className={styles.logo} alt={`${config.general.businessName}`} />
        </div>
        {renderList()}
      </Drawer>
    </div>
  );
};

export default NavDrawer;
