import React from "react";

const Icon = props => (
  <svg viewBox="0 0 212 160" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <circle stroke="currentColor" cx="106" cy="80" r="75" fill="none" strokeMiterlimit="10" strokeWidth="7" />
    <path fill="currentColor" d="m50.49 29.9 5.41-5.42L161.52 130.1l-5.41 5.42zM129.35 114.77a40.75 40.75 0 0 1-19.39 7c-.81.08-1-.14-1-.89v-7.38c0-2.09-1.19-3.46-3-3.49s-3.05 1.39-3.06 3.53v7.26c0 .8-.12 1.06-1 1A42.23 42.23 0 0 1 64.24 84c-.08-.76.07-1 .87-1h7.73a3 3 0 0 0 2.89-1.82 2.81 2.81 0 0 0-.42-3.07A3 3 0 0 0 72.84 77H64.98c-.7 0-.82-.19-.76-.86a40.73 40.73 0 0 1 7-19.48l-4.52-4.52a52.68 52.68 0 0 0-3.36 5.36 47.4 47.4 0 0 0-5.42 17.92c-.09 1-.48 1.25-1.4 1.23-2.42 0-4.84-.05-7.25 0A3 3 0 0 0 46 79.3v1.4a2.77 2.77 0 0 0 1.08 2 4.84 4.84 0 0 0 2.56.7h7c.82 0 1.18.26 1.27 1.11a39.48 39.48 0 0 0 .81 5.32Q64.69 115 88.61 125a42 42 0 0 0 12.7 3c1.06.1 1.37.56 1.35 1.57q-.09 3.57 0 7.14a3 3 0 0 0 2.64 3.29h1.4a2.68 2.68 0 0 0 2.31-1.7 4.94 4.94 0 0 0 .35-1.94v-7c0-.82.26-1.21 1.11-1.3s1.47-.11 2.2-.23a51 51 0 0 0 21.24-8.49ZM162.71 76.65h-7.37c-.82 0-1.26-.26-1.29-1.11 0-.31-.1-.61-.13-.92a41.45 41.45 0 0 0-1.89-9 48.91 48.91 0 0 0-28.41-30.52 47.64 47.64 0 0 0-13-3.16c-1-.09-1.25-.47-1.23-1.4 0-2.41.05-4.84 0-7.25A3 3 0 0 0 106.7 20h-1.4a3.07 3.07 0 0 0-2.65 3.29v7.25c0 .92-.27 1.3-1.23 1.4a48.24 48.24 0 0 0-14.76 3.89 50.8 50.8 0 0 0-8.53 4.88l4.51 4.52a41.63 41.63 0 0 1 19.36-7c.86-.09 1 .16 1 1v7.38c0 2.02 1.26 3.39 3 3.39a3.07 3.07 0 0 0 3-3.37v-7.5c0-.75.14-1 1-.89a41.17 41.17 0 0 1 24.58 11.11 41.61 41.61 0 0 1 13.13 25.58c.28 2 .3 2-1.78 2h-6.44c-2.15 0-3.49 1.3-3.49 3.07s1.38 3 3.45 3h7.27c.81 0 1.14.07 1 1a42.16 42.16 0 0 1-6.95 19.34l4.44 4.44a57.78 57.78 0 0 0 5.64-10.22A42.26 42.26 0 0 0 154 84.69c.1-1.06.56-1.37 1.57-1.35q3.57.09 7.14 0A3 3 0 0 0 166 80.7v-1.4a3.07 3.07 0 0 0-3.29-2.65Z" />
  </svg>
);

export default Icon;
