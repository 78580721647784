import React from "react";
import PropTypes from "prop-types";

import Header from "../header/Header";

const Page = ({ children }) => (
  <>
    <Header />
    <main id="home">
      {children}
    </main>
  </>
);

Page.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Page;
