import React, { useState } from "react";
import { Link } from "react-scroll";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cx from "classnames";

import NavDrawer from "./nav-drawer/NavDrawer";

import TelegramIcon from "../../components/icons/Telegram";
import TwitterIcon from "../../components/icons/Twitter";

import config from "../../config/config";

import styles from "./header.module.scss";

const Header = () => {
  const [transHeader, setTransHeader] = useState(window.scrollY > 40);

  useScrollPosition(
    ({ currPos }) => {
      if (Math.abs(currPos.y) > 40 && !transHeader) setTransHeader(true);
      if (Math.abs(currPos.y) <= 40 && transHeader) setTransHeader(false);
    },
    [transHeader],
  );

  return (
    <>
      <header className={cx(styles.cnt, { [styles.cntTrans]: transHeader })}>
        <div className={cx(styles.cntIn, { [styles.cntInTrans]: transHeader })}>
          <div className={styles.cntDrawer}>
            <NavDrawer />
            <Link to="home" className={styles.cntLogo} smooth={true} duration={500}>
              <img src={`/images/${config.header.logoSrc1}`} alt={`${config.general.businessName}`} />
            </Link>
          </div>
          <div className={styles.cntMenu}>
            <ul className={styles.cntTxtLinks}>
              <li>
                <Link to="features" className={styles.txtLink} smooth={true} duration={500} offset={-76}>Features</Link>
              </li>
              <li>
                <Link to="guide" className={styles.txtLink} smooth={true} duration={500} offset={-76}>Guide</Link>
              </li>
              <li>
                <Link to="apply" className={styles.txtLink} smooth={true} duration={500} offset={-120}>Apply</Link>
              </li>
              <li>
                <Link to="contact" className={styles.txtLink} smooth={true} duration={500} offset={-120}>Contact</Link>
              </li>
            </ul>
            <ul className={styles.cntIconLinks}>
              <li>
                <a href={config.general.telegramURL} className={styles.icnLink} target="_blank" rel="noopener noreferrer" title="Telegram"><TelegramIcon /></a>
              </li>
              <li>
                <a href={config.general.twitterURL} className={styles.icnLink} target="_blank" rel="noopener noreferrer" title="Twitter"><TwitterIcon /></a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
