import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Marquee from "react-fast-marquee";

import config from "../../../config/config";

import styles from "./benefits-marquee.module.scss";

const BenefitsMarquee = () => {
  const fullGradientWidth = useMediaQuery("(min-width: 800px)");

  return (
    <div className={styles.cnt}>
      <Marquee speed={config.benefitsMarquee.speed} gradientWidth={!fullGradientWidth ? 50 : 200}>
        {config.benefitsMarquee.bullets.map((item, idx) => (<p key={`item${idx}`} className={styles.item} dangerouslySetInnerHTML={{ __html: item }} />))}
      </Marquee>
    </div>
  );
};

export default BenefitsMarquee;
