import React from "react";

import Intro from "./intro/Intro";
import BenefitsMarquee from "./benefits-marquee/BenefitsMarquee";
import Features from "./features/Features";
import Guide from "./guide/Guide";
import Footer from "../../app/footer/Footer";

import styles from "./home.module.scss";

const Home = () => {
  return (
    <>
      <div className={styles.cont}>
        <Intro />
        <div className={styles.cont_in}>
          <BenefitsMarquee />
          <Features />
          <Guide />
          <Footer />
        </div>
      </div>
    </>
  )
};

export default Home;
