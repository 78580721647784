import React from "react";

const Icon = props => (
  <svg viewBox="0 0 212 160" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <circle stroke="currentColor" cx="106" cy="80" r="75" fill="none" strokeMiterlimit="10" strokeWidth="7" />
    <path fill="currentColor" d="M63 100.64a12.79 12.79 0 0 1-6.21-1.37A8.72 8.72 0 0 1 53 95.15a19.1 19.1 0 0 1-1.46-7l7-1.19a20.94 20.94 0 0 0 .52 4.15 5 5 0 0 0 1.29 2.48 3 3 0 0 0 2.16.82 2.3 2.3 0 0 0 2.17-1 4.52 4.52 0 0 0 .57-2.29 7.13 7.13 0 0 0-1.22-4.24 20.15 20.15 0 0 0-3.2-3.46l-4.18-3.62A17 17 0 0 1 53 75.52a11.33 11.33 0 0 1-1.41-5.86c0-3.31 1-5.85 2.9-7.62a11.39 11.39 0 0 1 8-2.66 10.91 10.91 0 0 1 5 1 7.66 7.66 0 0 1 3 2.66 10.92 10.92 0 0 1 1.51 3.6 25.14 25.14 0 0 1 .52 3.9l-7 1a32.11 32.11 0 0 0-.33-3.23 4.36 4.36 0 0 0-.89-2.18 2.49 2.49 0 0 0-2-.8 2.38 2.38 0 0 0-2.14 1.07 4.26 4.26 0 0 0-.69 2.36 6 6 0 0 0 1 3.5 17.93 17.93 0 0 0 2.66 2.86l4.07 3.57a23.31 23.31 0 0 1 4.35 5 12.5 12.5 0 0 1 1.81 6.93A10.21 10.21 0 0 1 72 95.77a9.25 9.25 0 0 1-3.6 3.58 11 11 0 0 1-5.4 1.29ZM50.49 29.9l5.41-5.42L161.52 130.1l-5.41 5.42zM137.51 100.09h.18l.55-27.47 5.36 27.47h5.12l5.12-27.27.64 27.27h5.96l-.69-40.23h-8.4l-5.31 25.38-4.97-25.38h-8.69l-.6 34.5 5.73 5.73zM114.45 77.03l1.04-7.04 1.42 9.5 8.52 8.52-5.42-28.15h-9.09l-2.22 11.42 5.75 5.75zM105 90.41l-1.88 9.68h7.7l.57-3.28-6.39-6.4zM99.82 85.24h-7.68v3.93A21.51 21.51 0 0 1 92 91.9a3.84 3.84 0 0 1-.88 2.1 2.88 2.88 0 0 1-2.26.77 3.24 3.24 0 0 1-2.21-.67 3.48 3.48 0 0 1-1-1.94 14.26 14.26 0 0 1-.27-3v-18.4l-6.6-6.6a11.66 11.66 0 0 0-.66 1.48 22.16 22.16 0 0 0-1 7.18v14.46a21.45 21.45 0 0 0 1 7A9 9 0 0 0 81.73 99a12.88 12.88 0 0 0 7.08 1.69 12.62 12.62 0 0 0 6.86-1.57 8.41 8.41 0 0 0 3.43-4.32 18.06 18.06 0 0 0 1-6.23v-3.06Z" />
  </svg>
);

export default Icon;
