import React from "react";
import { Link } from "react-scroll";
import Fade from "react-reveal/Fade";

import TelegramIcon from "../../components/icons/Telegram";
import TwitterIcon from "../../components/icons/Twitter";

import config from "../../config/config";

import styles from "./footer.module.scss";

const Footer = () => {
  const dateToday = new Date();

  return (
    <footer className={styles.cnt}>
      <Fade bottom distance="75px">
        <div className={styles.cntInA}>
          <Link to="home" className={styles.cntLogo} smooth={true} duration={750}>
            <img src={`/images/${config.header.logoSrc1}`} alt={`${config.general.businessName}`} />
          </Link>
          <ul className={styles.cntBtnLinks}>
            <li>
              <Link to="guide" className={styles.btnLinkA} smooth={true} duration={500} offset={-76}>Guide</Link>
            </li>
            <li>
              <Link to="apply" className={styles.btnLinkB} smooth={true} duration={500} offset={-120}>Apply</Link>
            </li>
          </ul>
          <ul className={styles.cntIconLinks}>
            <li>
              <a href={config.general.telegramURL} className={styles.icnLink} target="_blank" rel="noopener noreferrer" title="Telegram"><TelegramIcon /></a>
            </li>
            <li>
              <a href={config.general.twitterURL} className={styles.icnLink} target="_blank" rel="noopener noreferrer" title="Twitter"><TwitterIcon /></a>
            </li>
          </ul>
        </div>
      </Fade>
      <div className={styles.cntCopy}>
        <div className={styles.cntCopyIn}>
          <p>The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the website's content as such. {config.general.businessName} does not recommend that any cryptocurrency or token should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.</p>
          <p>Copyright &copy; {config.general.businessName} {dateToday.getFullYear()}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
