import React, { useState } from "react";
import { Link } from "react-scroll";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import config from "../../../config/config";

import styles from "./intro.module.scss";

const Intro = () => {
  const dynamicCntOpacity = useMediaQuery("(min-width: 1370px)");
  const canShowVideo = useMediaQuery("(min-width: 1370px)");
  const [cntOpacity, setCntOpacity] = useState(1);

  useScrollPosition(
    ({ currPos }) => {
      let newOpacity;

      if (!dynamicCntOpacity) {
        newOpacity = 1;
      } else {
        const rawOpacity = (1 - (Math.abs(currPos.y) / (window.innerHeight - 70))).toFixed(2);

        newOpacity = rawOpacity < 0 ? 0 : rawOpacity > 1 ? 1 : rawOpacity;
      }

      if (newOpacity !== cntOpacity) setCntOpacity(newOpacity);
    },
    [dynamicCntOpacity, cntOpacity],
  );

  return (
    <>
      <div className={styles.cnt}>
        <div className={styles.cntInA} style={{ opacity: cntOpacity }}>
          <div className={styles.cntInB}>
            <div className={styles.colA}>
              <h1 className={styles.cntHeading} dangerouslySetInnerHTML={{ __html: config.intro.heading }} />
              <div className={styles.cntCopy}>
                {config.intro.copyParas.map((item, idx) => (<p key={`item${idx}`} dangerouslySetInnerHTML={{ __html: item }} />))}
              </div>
              <ul className={styles.cntBtnLinks}>
                <li>
                  <Link to="guide" className={styles.btnLinkA} smooth={true} duration={500} offset={-76}>Guide</Link>
                </li>
                <li>
                  <Link to="apply" className={styles.btnLinkB} smooth={true} duration={500} offset={-120}>Apply</Link>
                </li>
              </ul>
            </div>
            <div className={styles.colB}>
              <img src={`/images/${config.intro.mainImgSrc}`} alt="" />
            </div>
          </div>
          {(canShowVideo && config.intro.videoBgSrc) && (
            <div className={styles.cntVideo}>
              <video autoPlay loop muted playsInline>
                <source src={`/videos/${config.intro.videoBgSrc}`} />
              </video>
            </div>
          )}
          <div 
            className={styles.cntImgBg} 
            style={{
              backgroundImage: `url("/images/${config.intro.imgBgSrc}")`,
              opacity: (canShowVideo && config.intro.videoBgSrc) ? config.intro.imgBgOpacity : 1
            }} 
          />
        </div>
      </div>
      <div className={styles.spacer}></div>
    </>
  );
};

export default Intro;
