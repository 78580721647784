import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "fast-text-encoding/text";

import React from "react";
import ReactDOM from "react-dom";

import App from "./app/App";
import routes from "./routes";

import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <App routes={routes} />
  </React.StrictMode>,
  document.getElementById("root")
);