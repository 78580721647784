import React from "react";

const Icon = props => (
  <svg viewBox="0 0 212 160" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <path fill="currentColor" d="M101.82 130.5v-12.43c-7.14-.07-14.52-2.33-18.82-5.41l3-8.24a32.8 32.8 0 0 0 17.71 5.29c8.74 0 14.64-5 14.64-12.06 0-6.76-4.8-11-13.9-14.64-12.6-4.92-20.35-10.58-20.35-21.28 0-10.21 7.26-18 18.58-19.8V29.5h7.63v11.93a32.87 32.87 0 0 1 16 4.31l-3.08 8.12a29.67 29.67 0 0 0-15.5-4.18c-9.47 0-13 5.65-13 10.58 0 6.39 4.55 9.59 15.26 14C122.61 79.45 129 85.84 129 96.79c0 9.72-6.76 18.82-19.43 20.92v12.79Z" />
    <path fill="currentColor" d="M157.31 130.5A72 72 0 1 1 178 80v1.07h7V80a79 79 0 1 0-23 55.71Z" />
    <path fill="currentColor" d="M193.69 116.16V87.39a1.32 1.32 0 0 0-1.32-1.32h-29.79a1.32 1.32 0 0 0-1.32 1.32v28.77a1.32 1.32 0 0 1-1.31 1.32h-4.67a1.32 1.32 0 0 0-1 2.2l22.2 24.67a1.32 1.32 0 0 0 2 0l22.2-24.67a1.32 1.32 0 0 0-1-2.2H195a1.32 1.32 0 0 1-1.31-1.32Z" />
  </svg>
);

export default Icon;
