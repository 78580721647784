import React from "react";

const Icon = props => (
  <svg viewBox="0 0 212 160" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <path fill="currentColor" d="M151.78 5a4 4 0 0 0-4-4h-121a4 4 0 0 0-4 4v150a4 4 0 0 0 4 4h74a4 4 0 0 0 4-4 4 4 0 0 0-4-4H33.74a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3H147.8a4 4 0 0 0 3.98-4Z" />
    <rect fill="currentColor" width="82.35" height="7.96" x="46.61" y="35" rx="3.98" />
    <rect fill="currentColor" width="46.35" height="7.96" x="46.61" y="72" rx="3.98" />
    <rect fill="currentColor" width="34.35" height="7.96" x="46.61" y="108" rx="3.98" />
    <path fill="currentColor" d="m188.05 152.21-20.45-20.46a41.59 41.59 0 1 0-5.74 5.52l20.57 20.56a4 4 0 0 0 5.62 0 4 4 0 0 0 0-5.62Zm-85.69-47.56A33.68 33.68 0 1 1 136 138.33a33.68 33.68 0 0 1-33.64-33.68Z" />
  </svg>
);

export default Icon;
